<script setup>
import { useSettings } from "~/composables/useSettings";

const { settings } = useSettings();

const props = defineProps(["modelValue"]);

const selectedFontSize = ref(null);
const fontsSizesColl = [
  { label: "Small", value: "sm" },
  { label: "Medium", value: "md" },
  { label: "Large", value: "lg" },
];
function watchProps() {
  watch(
    () => selectedFontSize.value,
    () => {
      settings.fontSize = selectedFontSize.value;
    }
  );
}

onMounted(() => {
  // load settings
  selectedFontSize.value = settings.fontSize;
  // watch settings
  watchProps();
});
</script>
<template>
  <v-segment
    v-model="selectedFontSize"
    :items="fontsSizesColl"
    :options="{
      handleColor: '#fff',
      handleBgColor: '#03bfa4',
    }"
    item-label="label"
    item-value="value"
    class="sources-segment"
  ></v-segment>
</template>
<style scoped>
.tab-group .active {
  background-color: var(--root-green);;
  color: var(--root-white);
  box-shadow: 0px 7px 12px 0px rgba(0, 191, 164, 0.25);
}
.tab-group {
  display: flex;
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  font-weight: 600;
  box-shadow: 0px 4px 11px 0px #e1e5f1;
  background-color: var(--root-white);
}
.v-btn {
  width: 100%;
}
.sm {
  font-size: 15px;
  border-right: solid 1px #e4e4e4;
}
.md {
  font-size: 22px;
  border-right: solid 1px #e4e4e4;
}
.lg {
  font-size: 28px;
}
</style>
